<template>
  <div class="ios-framework-box" :style="{ width, height }">
    <!-- 背景框 -->
    <img class="framework-img" :src="frameworkImg" alt="" />

    <!-- 刘海 -->
    <img class="fringe-img" :src="fringeImg" alt="" />

    <!-- 状态栏 -->
    <img class="top-navbar-img" :src="topNavbarImg" alt="" />

    <!-- 内容 -->
    <slot></slot>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  width: {
    type: String,
    default: "395px",
  },
  height: {
    type: String,
    default: "100%",
  },
});

// 图片
// 模拟框
const frameworkImg = require("@/assets/images/imitatepage/free-demo-form/frame-line.png");
// 刘海
const fringeImg = require("@/assets/images/imitatepage/free-demo-form/fringe.png");
// 状态栏
const topNavbarImg = require("@/assets/images/imitatepage/free-demo-form/top-navbar.png");
</script>

<style lang="scss">
.ios-framework-box {
  position: relative;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;

  .framework-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .fringe-img {
    position: absolute;
    width: 50%;
    left: 25%;
    top: 0;
    z-index: 9;
  }

  .top-navbar-img {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 10px;
    box-sizing: border-box;
    height: 54px;
    padding-bottom: 0;
    z-index: 1;
  }
}
</style>
