/* 商机小程序 */
export const businessOportunity = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
    position: "sticky",
  },
  // 免费试用按钮配置
  freeTrialButtonConfig: {
    // 主题 dark|light
    theme: "dark",
    // 项目名称
    projectName: "businessOportunity",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/business-oportunity/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `提供第二职业平台，给用户实现技能增值化，可以用自身技能在平台上接单，企业可以在平台上发布自身企业提供的服务，也可以在平台上选择所需服务，减少筛选合适技能人员时间以及非必要的人员储备。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/business-oportunity/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/business-oportunity/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 东深运维
    {
      image: require("@/assets/images/project-list-card/project-8.png"),
      path: "/",
      routeQuery: { name: "dongshenOperation" },
    },
    // 石油产业链金融电子商务平台
    {
      image: require("@/assets/images/project-list-card/project-9.png"),
      path: "/",
      routeQuery: { name: "guanghuiCloudOil" },
    },
    // 金融机构现金业务综合评价系统
    {
      image: require("@/assets/images/project-list-card/project-6.png"),
      path: "/",
      routeQuery: { name: "zhBankingFinance" },
    },
  ],
};

/* 内部招商系统 */
export const attractInvestment = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/attract-investment/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `主要针对企业内部商户租赁周期与合同管理，完成线上线下数字化转型，可以自动根据商户的租赁周期提前发起招商，并同步至公众号端自动分配员工进行跟踪。
  对已有品牌与经营品类进行管理，可以有效控制资源，避免入驻商家重复。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/attract-investment/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/attract-investment/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 获客e系统
    {
      image: require("@/assets/images/project-list-card/project-1.png"),
      path: "/",
      routeQuery: { name: "customerAcquisitionFinance" },
    },
    // 智能设备系统
    {
      image: require("@/assets/images/project-list-card/project-2.png"),
      path: "/",
      routeQuery: { name: "intelligentDevices" },
    },
    // 喜餐小程序
    {
      image: require("@/assets/images/project-list-card/project-7.png"),
      path: "/",
      routeQuery: { name: "happyMeal" },
    },
  ],
};

/* 获客e系统 */
export const customerAcquisitionFinance = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(252, 104, 75, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/customer-acquisition-finance/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `客户关系短期化？客户的市场细分不到位？缺乏专业的维护？ 获客E系统主要用于银行内部，业务人员主动获取客户信息，并上门营销帮助进行预约开通。且数据能同步到自助预约系统后台，柜员拿到内部营销数据安排客户，结果能够返回获客系统。令银行在客户身上产生更多的交叉销售的机会，培养客户对银行的忠诚度，真正达到银行与客户双赢。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/customer-acquisition-finance/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/customer-acquisition-finance/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 蔚然云木
    {
      image: require("@/assets/images/project-list-card/project-10.png"),
      path: "/",
      routeQuery: { name: "weiranYunmu" },
    },
    // 农产品溯源系统
    {
      image: require("@/assets/images/project-list-card/project-26.png"),
      path: "/",
      routeQuery: { name: "agriculturalProductSystem" },
    },
    // 云宠智能App
    {
      image: require("@/assets/images/project-list-card/project-4.png"),
      path: "/",
      routeQuery: { name: "cloudPetIntelligence" },
    },
  ],
};

/* 智能设备系统*/
export const intelligentDevices = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/intelligent-evices/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `该项目是应用于国外的一款智能设备、集成了智能穿戴设备（墨镜）与家庭智能设备（浴室音响），应用程序与智能设备连接以实现设备控制。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/intelligent-evices/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/intelligent-evices/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 南天司法
    {
      image: require("@/assets/images/project-list-card/project-5.png"),
      path: "/",
      routeQuery: { name: "nantianJudicial" },
    },
    // 喜餐小程序
    {
      image: require("@/assets/images/project-list-card/project-7.png"),
      path: "/",
      routeQuery: { name: "happyMeal" },
    },
    // 音响租赁系统
    {
      image: require("@/assets/images/project-list-card/project-21.png"),
      path: "/",
      routeQuery: { name: "audioRentalSystem" },
    },
  ],
};

/* 智能AR眼镜*/
export const smartArGlasses = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/smart-ar-glasses/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `AR智能眼镜具有“实时虚实结合信息”的远程监控和指导功能，通过提供沉浸式和交互式的远程通讯体验，可辅助一线工作人员完成设备安装、维护、检修等工作。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/smart-ar-glasses/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/smart-ar-glasses/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 服务器后台
    {
      image: require("@/assets/images/project-list-card/project-27.png"),
      path: "/",
      routeQuery: { name: "smartBracelet" },
    },
    // 深圳国际仲裁院
    {
      image: require("@/assets/images/project-list-card/project-13.png"),
      path: "/",
      routeQuery: { name: "szArbitration" },
    },
    // 金融机构现金业务综合评价系统
    {
      image: require("@/assets/images/project-list-card/project-6.png"),
      path: "/",
      routeQuery: { name: "zhBankingFinance" },
    },
  ],
};

/* 南天司法*/
export const nantianJudicial = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(19, 84, 187, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/nantian-judicial/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `南天司法项目有案件管理、数据统计、案件登记、待办事项、合同履约监控、鉴定技术管理、以及人力、财务、市场管理等，性能稳定、操作方便，能够较好地满足南天司法鉴定管理需求，极大地提高南天司法鉴定管理效率。创新丰富了司法鉴定管理与使用衔接机制的工作方式，实现了南天司法鉴定动态监督管理，促进南天司法公正、提高审判质量与效率将发挥重要的保障作用。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/nantian-judicial/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/nantian-judicial/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 中建钢构
    {
      image: require("@/assets/images/project-list-card/project-25.png"),
      path: "/",
      routeQuery: { name: "cscecSteelStructure" },
    },
    // 共享健身房
    {
      image: require("@/assets/images/project-list-card/project-28.png"),
      path: "/",
      routeQuery: { name: "sharedGym" },
    },
    // 共享充电宝
    {
      image: require("@/assets/images/project-list-card/project-22.png"),
      path: "/",
      routeQuery: { name: "sharedPowerBank" },
    },
  ],
};

/* 喜餐小程序*/
export const happyMeal = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/happy-meal/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `依托O2O方式，完成线上与线下交易的互通，完整实现在线下单、接单、统计、配送、运输全流程服务，完美的达成了资源整合服务、便捷消费者服务等目的。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/happy-meal/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/happy-meal/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 内部招商系统
    {
      image: require("@/assets/images/project-list-card/project-33.png"),
      path: "/",
      showFreeTrialTag: true,
      backgroundColor: "rgba(115, 192, 151, 1)",
      routeQuery: { name: "attractInvestment" },
    },
    // 深圳第二人民医院口腔管理系统
    {
      image: require("@/assets/images/project-list-card/project-15.png"),
      path: "/",
      routeQuery: { name: "oralManagementSystem" },
    },
    // 家庭储能管理系统
    {
      image: require("@/assets/images/project-list-card/project-12.png"),
      path: "/",
      routeQuery: { name: "householdEnergyStorage" },
    },
  ],
};

/* 云宠智能App*/
export const cloudPetIntelligence = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#2f6efd",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/cloud-pet-intelligence/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `1.智能便捷，互联互通 可在APP上查看每个设备的运行状态，是否关闭或开启。
  除此之外，还实现了远程视频功能，随时了解宠物情况。<br />
  2.远程操控，轻松易用手机一键绑定，轻松掌握设备添加与操作，随心远程操控智能产品。<br />
  3.设备分享，趣味传递 可分享设备给家人和朋友，共同享受科技带来的乐趣。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/cloud-pet-intelligence/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/cloud-pet-intelligence/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 蔚然云木
    {
      image: require("@/assets/images/project-list-card/project-10.png"),
      path: "/",
      routeQuery: { name: "weiranYunmu" },
    },
    // 蓝网科技远程会诊
    {
      image: require("@/assets/images/project-list-card/project-11.png"),
      path: "/",
      routeQuery: { name: "blueNet" },
    },
    // 家庭储能系统
    {
      image: require("@/assets/images/project-list-card/project-12.png"),
      path: "/",
      routeQuery: { name: "householdEnergyStorage" },
    },
  ],
};

/* 东深运维小程序*/
export const dongshenOperation = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(92, 154, 255, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/dongshen-operation/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `通过告警归并、LogReduce数据概要、KPI相关度计算等，让分析人员快速聚焦主要问题。通过时序预测、性能瓶颈分析等实现性能优化及成本管理。通过多种智能运维算法，实现异常自动检测、故障根因分析。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/dongshen-operation/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/dongshen-operation/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 健康管理服务
    {
      image: require("@/assets/images/project-list-card/project-20.png"),
      path: "/",
      routeQuery: { name: "inspectionAlliance" },
    },
    // 中建钢构
    {
      image: require("@/assets/images/project-list-card/project-25.png"),
      path: "/",
      routeQuery: { name: "cscecSteelStructure" },
    },
    // 共享充电宝
    {
      image: require("@/assets/images/project-list-card/project-22.png"),
      path: "/",
      routeQuery: { name: "sharedPowerBank" },
    },
  ],
};

/* 石油产业链金融电子商务平台*/
export const guanghuiCloudOil = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(251, 202, 88, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/guanghui-cloud-oil/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `光汇石油是依托其实业资源打造全国首家“能源产业链+互联网”的能源电子商务平台，改变传统石油消费模式，通过手机App加油站为车主提供线上储油、线下加油消费服务，使用户可以自主规划用油成本，享受用油、储油、增值服务等全新的油品消费体验。 面向所有加油客户的自助服务软件，以加油支付和加油服务为核心，围绕“人、车、生活”，为客户提供高效、便捷、智能的服务平台。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/guanghui-cloud-oil/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/guanghui-cloud-oil/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 智能配电箱
    {
      image: require("@/assets/images/project-list-card/project-29.png"),
      path: "/",
      routeQuery: { name: "intelligentPowerDistribution" },
    },
    // 北线监控系统
    {
      image: require("@/assets/images/project-list-card/project-30.png"),
      path: "/",
      routeQuery: { name: "northLineMonitoring" },
    },
    // 新能源充电桩系统
    {
      image: require("@/assets/images/project-list-card/project-17.png"),
      path: "/",
      routeQuery: { name: "newEnergy" },
    },
  ],
};

/* 金融机构现金业务综合评价系统*/
export const zhBankingFinance = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/zh-banking-finance/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `随着商业银行改革的不断深入和流程银行理念的树立，对全行现金业务管理水平提出了更高的要求。未来商业银行将现金管理全部实现电子化，信息化和智能化，现金、贵金属、有价单证等的配送实现现代化物流管理。 该系统可满足商业银行未来十年的发展需要，提供严密的事前风控的同时又保证了超低延迟，成就专业和机构投资者既安全又快的交易。为代理发行货币基金、自营贵金属、有价证券发行、电子保管箱、自助银行、智能金库等业务提供有力的支撑平台。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/zh-banking-finance/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/zh-banking-finance/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 智能储物柜
    {
      image: require("@/assets/images/project-list-card/project-23.png"),
      path: "/",
      routeQuery: { name: "intelligentLocker" },
    },
    // 公安局智慧社区
    {
      image: require("@/assets/images/project-list-card/project-31.png"),
      path: "/",
      routeQuery: { name: "intelligentDoorLock" },
    },
    // 病房视讯服务系统
    {
      image: require("@/assets/images/project-list-card/project-16.png"),
      path: "/",
      routeQuery: { name: "wardVideoSystem" },
    },
  ],
};

/* 蔚然云木官网*/
export const weiranYunmu = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/weiran-yunmu/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `该项目是一款网页端电商购物应用，为用户提供在线丰富的化妆品种类，可在该项目内进行下单、支付、确认收货、评价等全流程操作。该项目在UI界面设计花费了较多的心思，完全贴合品牌调性，升级了品牌视觉效果。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/weiran-yunmu/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/weiran-yunmu/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 健康管理服务
    {
      image: require("@/assets/images/project-list-card/project-20.png"),
      path: "/",
      routeQuery: { name: "inspectionAlliance" },
    },
    // TCL-T快运物流系统
    {
      image: require("@/assets/images/project-list-card/project-14.png"),
      path: "/",
      routeQuery: { name: "logisticsSystem" },
    },
    // BTOC商城
    {
      image: require("@/assets/images/project-list-card/project-19.png"),
      path: "/",
      routeQuery: { name: "btocShoppingMall" },
    },
  ],
};

/*家庭储能管理系统*/
export const householdEnergyStorage = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/household-energy-storage/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `本项目是投放在国外的一款家庭储能项目，通俗来讲就是把电能储存起来，待需要时使用——也称为电力储能产品或者“电池储能系统”（BESS）。该项目通过与硬件配合开发完成智能控制充放电的控制系统，实现能量时移和电费管理、后台管理等需求。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/household-energy-storage/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/household-energy-storage/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 冠字号后台
    {
      image: require("@/assets/images/project-list-card/project-24.png"),
      path: "/",
      routeQuery: { name: "crownSize" },
    },
    // ASM预算系统
    {
      image: require("@/assets/images/project-list-card/project-18.png"),
      path: "/",
      routeQuery: { name: "asmBudgetSystem" },
    },
    // 智能配电箱
    {
      image: require("@/assets/images/project-list-card/project-29.png"),
      path: "/",
      routeQuery: { name: "intelligentPowerDistribution" },
    },
  ],
};

/*中建钢构系统*/
export const cscecSteelStructure = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(96, 137, 217, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/cscec-steel-structure/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `中建钢构项目是装配式钢结构学校产品智能选型，注重实用性，将企业能实现的功能做好，让所有工程建设者都能用起来。并且不只是简单地进行功能堆砌，而是将自动采集上来的数据利用在企业管理上面，使智慧工地发挥更大的价值。企业本身结合优秀的软件和硬件产品的功能强项进行整体规划，实现建设成本可控、功能可迭代维护、实用性可保证的自给自足的智慧软件。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/cscec-steel-structure/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/cscec-steel-structure/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 东深运维
    {
      image: require("@/assets/images/project-list-card/project-8.png"),
      path: "/",
      routeQuery: { name: "dongshenOperation" },
    },
    // 石油产业链金融电子商务平台
    {
      image: require("@/assets/images/project-list-card/project-9.png"),
      path: "/",
      routeQuery: { name: "guanghuiCloudOil" },
    },
    // 金融机构现金业务综合评价系统
    {
      image: require("@/assets/images/project-list-card/project-6.png"),
      path: "/",
      routeQuery: { name: "zhBankingFinance" },
    },
  ],
};

/*TCL快运物流系统*/
export const logisticsSystem = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(0, 143, 255, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/logistics/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `十分到家实现清洗、送货、安装、保养、调试、维修、回收全程一站式服务，省心省事。致力打造无忧、环保、健康的新型居家生活方式。十分到家下单后，派单系统会将订单自动分配到该区域的工程师，10分钟之内，系统分配到的工程师会电话联系，约定上门服务时间，在非高峰时期，核心市区最快24个小时上门服务，身边的全能管家。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/logistics/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/logistics/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 获客e系统
    {
      image: require("@/assets/images/project-list-card/project-1.png"),
      path: "/",
      routeQuery: { name: "customerAcquisitionFinance" },
    },
    // 智能设备系统
    {
      image: require("@/assets/images/project-list-card/project-2.png"),
      path: "/",
      routeQuery: { name: "intelligentDevices" },
    },
    // 喜餐小程序
    {
      image: require("@/assets/images/project-list-card/project-7.png"),
      path: "/",
      routeQuery: { name: "happyMeal" },
    },
  ],
};

/*深圳国际仲裁院批量办案系统*/
export const szArbitration = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff29",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/sz-arbitration/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `该系统实现了立案、审理、审核、改派、归档等流程为一体的综合管理系统。应用网络化的办案执法管理系统。通过对执法的整个办案流程的规范化，达到增加管理透明度，提高公正性、公开性的目的。同时有效的解决基础案件往返、多头备案管理、文书操作繁琐、消耗人力、物力和浪费时间等问题。不仅提高工作效率、降低了办案成本；另一方面，改善了决策质量、增加了办公透明度。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/sz-arbitration/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/sz-arbitration/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 蔚然云木
    {
      image: require("@/assets/images/project-list-card/project-10.png"),
      path: "/",
      routeQuery: { name: "weiranYunmu" },
    },
    // 农产品溯源系统
    {
      image: require("@/assets/images/project-list-card/project-26.png"),
      path: "/",
      routeQuery: { name: "agriculturalProductSystem" },
    },
    // 云宠智能App
    {
      image: require("@/assets/images/project-list-card/project-4.png"),
      path: "/",
      routeQuery: { name: "cloudPetIntelligence" },
    },
  ],
};

/*蓝网科技远程会诊*/
export const blueNet = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/blue-net/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `通过实时互动技术，实现优质医疗资源和知识共享，解决医疗资源不均衡的难题。 实现了视频急救、互动诊疗、远程会诊、心理咨询、手术示教、医疗直播培训等业务场景。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/blue-net/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/blue-net/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 南天司法
    {
      image: require("@/assets/images/project-list-card/project-5.png"),
      path: "/",
      routeQuery: { name: "nantianJudicial" },
    },
    // 喜餐小程序
    {
      image: require("@/assets/images/project-list-card/project-7.png"),
      path: "/",
      routeQuery: { name: "happyMeal" },
    },
    // 音响租赁系统
    {
      image: require("@/assets/images/project-list-card/project-21.png"),
      path: "/",
      routeQuery: { name: "audioRentalSystem" },
    },
  ],
};

/*农产品溯源系统*/
export const agriculturalProductSystem = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(57, 114, 73, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/agricultural-product/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `“互联网+农产品”是溯源技术与农业的结合，也是用科技与传统行业相结合。溯源app使农产品生产过程透明、信息真实可信。通过溯源，消费者可以实时查看瓜果的成长信息，由于技术的原理决定了这些信息不可篡改、不能伪造，从而可以有效的确保瓜果信息的真实有效。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/agricultural-product/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/agricultural-product/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 服务器后台
    {
      image: require("@/assets/images/project-list-card/project-27.png"),
      path: "/",
      routeQuery: { name: "smartBracelet" },
    },
    // 深圳国际仲裁院
    {
      image: require("@/assets/images/project-list-card/project-13.png"),
      path: "/",
      routeQuery: { name: "szArbitration" },
    },
    // 金融机构现金业务综合评价系统
    {
      image: require("@/assets/images/project-list-card/project-6.png"),
      path: "/",
      routeQuery: { name: "zhBankingFinance" },
    },
  ],
};

/*深圳第二人民医院口腔管理系统*/
export const oralManagementSystem = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(110, 140, 250, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/oral-management/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `群众说起到医院看病，第一反应就是“等” 但是口腔患者管理系统，全新的医疗服务模式有效降低了门诊病人及家属时间上的盲目安排、无效折返情况。 “互联网医疗”形成了一套完善的门诊挂号预约体系；可以满足不同地区、不同年龄、不同文化层次，初诊、复诊、出院患者、疑难重症患者就诊需要；有效推动了分级诊疗，多维度便捷了患者就医。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/oral-management/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/oral-management/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 中建钢构
    {
      image: require("@/assets/images/project-list-card/project-25.png"),
      path: "/",
      routeQuery: { name: "cscecSteelStructure" },
    },
    // 共享健身房
    {
      image: require("@/assets/images/project-list-card/project-28.png"),
      path: "/",
      routeQuery: { name: "sharedGym" },
    },
    // 共享充电宝
    {
      image: require("@/assets/images/project-list-card/project-22.png"),
      path: "/",
      routeQuery: { name: "sharedPowerBank" },
    },
  ],
};

/*音响租赁系统*/
export const audioRentalSystem = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(240, 195, 50, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/audio-rental/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `项目拥有经验丰富的R&D无线专业研发团队，配备先进检测试验设备的中心实验室，还有生产规模涵盖各领域的麦克风制造设备。此外，公司还与国内外多家电声研究机构及专家建立了技术服务系统，是中国无线麦克风标准制定单位之一，保证随时掌握电声行业的最新资讯，为公司开发新产品和满足特定用户设计要求提供精确快捷满意的服务。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/audio-rental/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/audio-rental/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 获客e系统
    {
      image: require("@/assets/images/project-list-card/project-1.png"),
      path: "/",
      routeQuery: { name: "customerAcquisitionFinance" },
    },
    // 蔚然云木官网
    {
      image: require("@/assets/images/project-list-card/project-10.png"),
      path: "/",
      routeQuery: { name: "weiranYunmu" },
    },
    // 智能AR眼镜
    {
      image: require("@/assets/images/project-list-card/project-3.png"),
      path: "/",
      routeQuery: { name: "smartArGlasses" },
    },
  ],
};

/*服务器后台*/
export const smartBracelet = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(251, 184, 103, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/smart-bracelet/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `服务器后台智能化体现搭建企业级智能客户数据平台，管理会员和非会员客户，对客户运动步数以及运动类型分析，轻松完成实时客户数据采集，将专业知识和经验转化为数据沉淀，促进资源优化配置，提高效能，降低员工成本。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/smart-bracelet/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/smart-bracelet/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 冠字号后台
    {
      image: require("@/assets/images/project-list-card/project-24.png"),
      path: "/",
      routeQuery: { name: "crownSize" },
    },
    // ASM预算系统
    {
      image: require("@/assets/images/project-list-card/project-18.png"),
      path: "/",
      routeQuery: { name: "asmBudgetSystem" },
    },
    // 智能配电箱
    {
      image: require("@/assets/images/project-list-card/project-29.png"),
      path: "/",
      routeQuery: { name: "intelligentPowerDistribution" },
    },
  ],
};

/*共享充电宝*/
export const sharedPowerBank = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/shared-power-bank/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `公安局智慧社区解决方案是专门针对治安情况复杂，人员管理困难。构建智慧城市、智慧社区而量身定制。丽安居系在满足“群众性治安防控”目标下。以业主/租户体验为核心，以社区生活为场景，利用互联网、物联网、大数据、人工智能等技术，实现人、车、设备的互联、互通、开放、共享，为社区居民和社区物业带来一个全新的、数字化的智能综合管理系统。努力营造安全稳定的社会治安环境，为辖区百姓服务，提升辖区治安管理水平。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/shared-power-bank/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/shared-power-bank/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // TCL-T快运物流系统
    {
      image: require("@/assets/images/project-list-card/project-14.png"),
      path: "/",
      routeQuery: { name: "logisticsSystem" },
    },
    // 健康管理服务
    {
      image: require("@/assets/images/project-list-card/project-20.png"),
      path: "/",
      routeQuery: { name: "inspectionAlliance" },
    },
    // BTOC商城
    {
      image: require("@/assets/images/project-list-card/project-19.png"),
      path: "/",
      routeQuery: { name: "btocShoppingMall" },
    },
  ],
};

/*共享健身房*/
export const sharedGym = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/shared-gym/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `通过小程序将门店搬到线上，占据更多触达消费者的路径，实现顾客线上购卡、预约课程、参加活动的需求。企业展示更全面，全方面的介绍健身课程和结合微预约进行教练预约，收集潜在客户资源，精准消息通知提高触达用户，刺激转化。帮助健身行业多途径更快拉新客户、节约成本、提高效率，适应网络变革。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/shared-gym/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/shared-gym/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 健康管理服务
    {
      image: require("@/assets/images/project-list-card/project-20.png"),
      path: "/",
      routeQuery: { name: "inspectionAlliance" },
    },
    // 共享充电宝
    {
      image: require("@/assets/images/project-list-card/project-22.png"),
      path: "/",
      routeQuery: { name: "sharedPowerBank" },
    },
    // Btoc后台管理系统
    {
      image: require("@/assets/images/project-list-card/project-19.png"),
      path: "/",
      routeQuery: { name: "btocShoppingMall" },
    },
  ],
};

/*深圳龙岗公安局智慧门禁系统*/
export const intelligentDoorLock = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff1a",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/intelligent-door-lock/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `公安局智慧社区解决方案是专门针对治安情况复杂，人员管理困难。构建智慧城市、智慧社区而量身定制。丽安居系在满足“群众性治安防控”目标下。以业主/租户体验为核心，以社区生活为场景，利用互联网、物联网、大数据、人工智能等技术，实现人、车、设备的互联、互通、开放、共享，为社区居民和社区物业带来一个全新的、数字化的智能综合管理系统。努力营造安全稳定的社会治安环境，为辖区百姓服务，提升辖区治安管理水平。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/intelligent-door-lock/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/intelligent-door-lock/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 智能配电箱
    {
      image: require("@/assets/images/project-list-card/project-29.png"),
      path: "/",
      routeQuery: { name: "intelligentPowerDistribution" },
    },
    // 北线监控系统
    {
      image: require("@/assets/images/project-list-card/project-30.png"),
      path: "/",
      routeQuery: { name: "northLineMonitoring" },
    },
    // 新能源充电桩系统
    {
      image: require("@/assets/images/project-list-card/project-17.png"),
      path: "/",
      routeQuery: { name: "newEnergy" },
    },
  ],
};

/*健康管理服务平台*/
export const inspectionAlliance = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff1a",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/inspection-alliance/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `天使直通车APP提供一体化的健康管理服务，引进国外家庭医生理念，患者可下载APP端进行诊所查找，报告查询等，医生护士可在线进行解答、预约等，诊所同时也可以对第三方进行检验和合作，APP还具有在线招聘功能。实现便捷的管理，方便就医，省时省钱。联盟内检查及诊断结果的互联、互通、互认，实现重大疾病的精准化诊断，进一步提升检验技术水平及服务能力，建立以患者为中心的医疗机构之间的整合式医疗。更好的实现优质资源下沉，造福资兴百姓。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/inspection-alliance/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/inspection-alliance/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 智能储物柜
    {
      image: require("@/assets/images/project-list-card/project-23.png"),
      path: "/",
      routeQuery: { name: "intelligentLocker" },
    },
    // 公安局智慧社区
    {
      image: require("@/assets/images/project-list-card/project-31.png"),
      path: "/",
      routeQuery: { name: "intelligentDoorLock" },
    },
    // 病房视讯服务系统
    {
      image: require("@/assets/images/project-list-card/project-16.png"),
      path: "/",
      routeQuery: { name: "wardVideoSystem" },
    },
  ],
};

/*病房视讯服务系统*/
export const wardVideoSystem = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff1a",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/ward-video/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `病房视讯服务系统可以有效的覆盖医院住院部，大厅等场所。后台也可以灵活设置显示的信息以及播放的节目等各种信息。能够在医院实时的发布系统消息，医院宣教，医生简介等资料。为医院的信息化做出了巨大贡献，极大地提高医护人员的工作效率，为患者带来便利的同时也提高医院的整体形象。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/ward-video/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/ward-video/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 深圳国际仲裁院
    {
      image: require("@/assets/images/project-list-card/project-13.png"),
      path: "/",
      routeQuery: { name: "szArbitration" },
    },
    // 公安局智慧社区
    {
      image: require("@/assets/images/project-list-card/project-31.png"),
      path: "/",
      routeQuery: { name: "intelligentDoorLock" },
    },
    // 内部招商系统
    {
      image: require("@/assets/images/project-list-card/project-33.png"),
      path: "/",
      showFreeTrialTag: true,
      backgroundColor: "rgba(115, 192, 151, 1)",
      routeQuery: { name: "attractInvestment" },
    },
  ],
};

/*新能源充电桩系统*/
export const newEnergy = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(183, 77, 107, 0.07)",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/new-energy/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `骆驼快充APP，专注解决新能源电动汽车车主充电难题。支持智能寻桩、充电预约、可调电价（统一电价/峰平谷电价/阶梯电价）、扫码充电、实时计费五大功能，同时支持充电费用集团统付业务，可为汽车用电企业提供完整的充电解决方案。骆驼快充运营的充电桩包含国际交流慢充桩、国际直流快充桩等多个一线品牌的充电桩，致力于为广大车主朋友提供稳定可靠的充电服务。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/new-energy/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/new-energy/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 智能设备系统
    {
      image: require("@/assets/images/project-list-card/project-2.png"),
      path: "/",
      routeQuery: { name: "intelligentDevices" },
    },
    // 北线监控系统
    {
      image: require("@/assets/images/project-list-card/project-30.png"),
      path: "/",
      routeQuery: { name: "northLineMonitoring" },
    },
    // 中建钢构
    {
      image: require("@/assets/images/project-list-card/project-25.png"),
      path: "/",
      routeQuery: { name: "cscecSteelStructure" },
    },
  ],
};

/*智能储物柜*/
export const intelligentLocker = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff1a",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/intelligent-locker/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `在公众场所提供储物柜已经成为当前不可或缺的一种工具，在商场、超市， 储物柜的使用随处可见，现有技术中储物柜用户存取物品一般都需要物理凭证，如条码、IC卡、磁卡、条码卡等。其中条码打印方式需要耗材，如纸张其不符合环保节能的要求，管理人员需定时检查纸张使用情况，更为普通的技术问题是卡纸现象的发生，则需要定期更换、检修打印机。卡片储物柜则需要用户随身携带相应卡片，由于公众场所多以购物为主，若用户遗忘或遗失卡片将无法进行相应的存储物操作。条码储物柜、磁卡储物柜同样存在以上的问题，更为严重的是如果遗失相关的储物凭证后如被他人拾取，容易丢失物品，储物的安全性得不到有效保障。因此出现了智能储物柜 人脸识别密码储物柜采用先进的AI人脸面部识别技术，利用AI人脸部特征作为储物凭证，每个人的脸部特征是唯一的，均可被采集，人脸面部识别储物柜让储物更健康，更安全，更放心。 活体检测：采用红外活体检测技术，防止利用照片假冒； 准确率高：采用主动红外补光技术，可应用在室内外不同场合； 比对速度快：每秒30万次人脸比对，人脸识别通过时间<2秒； 安全性高：人脸几乎无法复制或仿冒，使用人脸识别特征作为存取凭证，无需保管物质凭证（如条码、磁卡等）存物更省心。没有耗材，寄存柜使用寿命更加长久，管理员管理更加方便。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/intelligent-locker/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/intelligent-locker/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 音响租赁系统
    {
      image: require("@/assets/images/project-list-card/project-21.png"),
      path: "/",
      routeQuery: { name: "audioRentalSystem" },
    },
    // 东深运维
    {
      image: require("@/assets/images/project-list-card/project-8.png"),
      path: "/",
      routeQuery: { name: "dongshenOperation" },
    },
    // 蔚然云木
    {
      image: require("@/assets/images/project-list-card/project-10.png"),
      path: "/",
      routeQuery: { name: "weiranYunmu" },
    },
  ],
};

/*智能配电箱*/
export const intelligentPowerDistribution = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff1a",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/intelligent-power-distribution/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `在如今现代社会中，电能在各行各业的联系非常的紧密，输配电及控制设备是智能电网建设中不可或缺的一部分，其作用是接受、分配和控制电能，保障用电设备和输电线路的正常工作，并将电能输送到用户。输配电及控制设备广泛应用于数字化变电站、配网自动化及配电管理、用户用电信息采集、智能调度等智能电网子产业。随着社会的进步和人民生活水平的提高，人们对现代家居的要求也越来越高，家里的用电设备很多，大分类包括厨房类电器、空调类电器、照明类电器、洗手间洁具和沐浴类电器，还有许多其它小家电，例如笔记本、充电宝之类；都可以搭配智能配电箱、智能家居网关等进行统一的管理，可在手机端下载APP轻松实现对家电的操控和管理，例如：远程操控、实时监测用电量、温度监测、故障报警、电费计算、断电保护等等功能都可以通过APP来操控，保障用电的状态和安全。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/intelligent-power-distribution/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/intelligent-power-distribution/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // TCL-T快运物流系统
    {
      image: require("@/assets/images/project-list-card/project-14.png"),
      path: "/",
      routeQuery: { name: "logisticsSystem" },
    },
    // 健康管理服务
    {
      image: require("@/assets/images/project-list-card/project-20.png"),
      path: "/",
      routeQuery: { name: "inspectionAlliance" },
    },
    // BTOC商城
    {
      image: require("@/assets/images/project-list-card/project-19.png"),
      path: "/",
      routeQuery: { name: "btocShoppingMall" },
    },
  ],
};

/*ASM预算系统*/
export const asmBudgetSystem = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff1a",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/asm-budget/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `该系统实时记录监控企业的收支情况，财务风险管控流程化。实现财务制度与报表展现，管理组织机构内部门、人员的费用。实现流程与收支管理、费用管理的集成。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/asm-budget/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/asm-budget/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 商机小程序
    {
      image: require("@/assets/images/project-list-card/project-32.png"),
      path: "/",
      showFreeTrialTag: true,
      backgroundColor: "rgba(38, 157, 255, 1)",
      routeQuery: { name: "businessOportunity" },
    },
    // 喜餐订货
    {
      image: require("@/assets/images/project-list-card/project-7.png"),
      path: "/",
      routeQuery: { name: "happyMeal" },
    },
    // 智能AR眼镜
    {
      image: require("@/assets/images/project-list-card/project-3.png"),
      path: "/",
      routeQuery: { name: "smartArGlasses" },
    },
  ],
};

/*冠字号后台系统*/
export const crownSize = {
  // 头部导航
  headerStyle: {
    backgroundColor: "#0077ff1a",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/crown-size/banner-1.png"),
  bannerHeight: "861px",
  // 项目介绍
  projectIntroduction: `冠字号后台的查询和打印，实现了对钞票来源的可追溯，是对“离柜概不负责”可能导致取款人取到假币受到损失的一种补救措施。具备自动识别、记录、存储存取款的客户姓名、存取款时间、存取款机位(工号)等功能，查看行内每一台设备的状态，厂商以及设备管理等。解决银行业金融机构涉假纠纷提供了数据支持。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/crown-size/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/crown-size/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 蔚然云木
    {
      image: require("@/assets/images/project-list-card/project-10.png"),
      path: "/",
      routeQuery: { name: "weiranYunmu" },
    },
    // 蓝网科技远程会诊
    {
      image: require("@/assets/images/project-list-card/project-11.png"),
      path: "/",
      routeQuery: { name: "blueNet" },
    },
    // 家庭储能系统
    {
      image: require("@/assets/images/project-list-card/project-12.png"),
      path: "/",
      routeQuery: { name: "householdEnergyStorage" },
    },
  ],
};

/*BroC后台系统*/
export const btocShoppingMall = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(44, 44, 44, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/btoc-shopping-mall/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `支持平台自营、商家入驻模式，满足企业各发展阶段的商业需求。全渠道、多终端一站式布局，有效整合资源，实现线上线下业务全覆盖，为项目方打造拥有核心竞争力的电商平台。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/btoc-shopping-mall/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/btoc-shopping-mall/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 健康管理服务
    {
      image: require("@/assets/images/project-list-card/project-20.png"),
      path: "/",
      routeQuery: { name: "inspectionAlliance" },
    },
    // 中建钢构
    {
      image: require("@/assets/images/project-list-card/project-25.png"),
      path: "/",
      routeQuery: { name: "cscecSteelStructure" },
    },
    // 共享充电宝
    {
      image: require("@/assets/images/project-list-card/project-22.png"),
      path: "/",
      routeQuery: { name: "sharedPowerBank" },
    },
  ],
};

/*北线监控系统*/
export const northLineMonitoring = {
  // 头部导航
  headerStyle: {
    backgroundColor: "rgba(127, 177, 255, 1)",
    navItemTheme: "light",
    logoTheme: "light",
  },
  // banner
  bannerImage: require("@/assets/images/list-case/north-line-monitoring/banner-1.png"),
  bannerHeight: "",
  // 项目介绍
  projectIntroduction: `承接市水务局的委托，实现水务站点、管道流向开关等功能，并以可视化页面进行展示。`,
  // 详情内容
  detailContentImage: require("@/assets/images/list-case/north-line-monitoring/content-1.png"),
  // 详情介绍
  detailIntroduction: `云效科技崇尚创新，专注研发，拥有一支国内领先的技术团队，并凭借专业知识强、业务技术精、服务质量高、精神面貌佳等特点得到用户的一致赞誉。在软件开发的专业领域，云效科技与行业内诸多知名企业保持有长期稳定的合作关系。`,

  // 预览图片
  previewImage: require("@/assets/images/list-case/north-line-monitoring/image-1.png"),
  // 相关案例
  relatedCaseList: [
    // 冠字号后台
    {
      image: require("@/assets/images/project-list-card/project-24.png"),
      path: "/",
      routeQuery: { name: "crownSize" },
    },
    // ASM预算系统
    {
      image: require("@/assets/images/project-list-card/project-18.png"),
      path: "/",
      routeQuery: { name: "asmBudgetSystem" },
    },
    // 智能配电箱
    {
      image: require("@/assets/images/project-list-card/project-29.png"),
      path: "/",
      routeQuery: { name: "intelligentPowerDistribution" },
    },
  ],
};
