<template>
  <div class="template-container mobile-wrap">
    <div class="wrap-box">
      <!-- 表单 -->
      <demoForm></demoForm>
    </div>
  </div>
</template>

<script setup>
import iosFrameworkBox from "@/components/ios-framework-box/index.vue";
import demoForm from "../../components/demoForm.vue";

import { computed, toRefs, getCurrentInstance, onMounted } from "vue";
import * as templates from "../data.js";
import { useRouter } from "vue-router";

const { proxy } = getCurrentInstance();

const mainStore = proxy.$usePiniaModule("mainStore");

// 控制框架组件
const controlLayout = (result) => {
  mainStore.showHeaderNavbar = result;
  mainStore.showDownFooter = result;
};

onMounted(() => {
  controlLayout(false);
});
</script>

<style lang="scss" scoped>
.template-container {
  overflow: hidden;
  height: 100%;

  &.mobile-wrap {
    .wrap-box {
      height: 100%;
      width: 100%;
      background-image: url(../../../../assets/images/imitatepage/free-demo-form/bg.png);
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: 100% 100%;
      position: relative;
      overflow: hidden;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}
</style>
