import { reactive, watch, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import piniaStore from "@/piniaStore";
export default function () {
  const proxy = this;
  const { mainStore, dataStore } = piniaStore;
  const useMainStore = mainStore();
  const useDataStore = dataStore();
  const router = useRouter();

  // ref
  const formRef = ref();

  // 参数
  const defaultClientInfo = () => ({
    contactWay: "",
    demandDescribe: "",
    named: "",
  });
  const clientForm = reactive(defaultClientInfo());
  const clientFormRules = {
    contactWay: [
      // {
      //   message: "手机号码不能低于11位",
      //   validator: (rule, value, callback) => {
      //     if (value.toString().length < 11) {
      //       callback(false);
      //     } else {
      //       callback();
      //     }
      //   },
      // },
    ],
  };

  // 监听表单填写
  watch(
    clientForm,
    (nVal) => {
      if (nVal.contactWay.toString().length >= 11) {
        addClientDemandEntry();
      }
    },
    {
      deep: true,
    }
  );

  // 提交需求
  const formSubmit = async (closeLoading) => {
    const call = async (closeLoading) => {
      const testRes = await proxy.$testForm(closeLoading, formRef.value);
      if (!testRes) {
        return;
      }

      // 获取差异值
      const diffData = proxy.$getDataUpdateAfter(
        defaultClientInfo(),
        clientForm
      );
      if (!Object.keys(diffData).length) {
        proxy.$message.warning("请先填写完整的需求资料");
        closeLoading();
        return;
      }

      try {
        await proxy.$storeDispatch("fetchAddClientDemand", clientForm);
        proxy.$message.success("提交成功");
        proxy.$updateParams(clientForm, defaultClientInfo());
        closeLoading();
      } catch (error) {
        closeLoading();
      }
    };

    // 记录鼠标操作
    useDataStore.setGlobalClickRecord(function () {
      document.getElementById("form-submit").click();
    });
    call(closeLoading);
  };

  // 添加客户需求词条
  const addClientDemandEntry = () => {
    const groupNo = useMainStore.getSubmitGroupNo();
    proxy.$storeDispatch("fetchAddClientDemandEntry", {
      ...clientForm,
      groupNo,
    });
  };

  // 返回
  const goBack = () => {
    // 记录鼠标操作
    // dataStore.setGlobalClickRecord(function () {
    //   document
    //     .getElementsByClassName("news-detail")[0]
    //     .getElementsByClassName("callBack")[0]
    //     .click();
    // });
    router.go(-1);
  };

  return {
    formRef,
    clientForm,
    clientFormRules,
    formSubmit,
    goBack,
  };
}
