<template>
  <div class="demo-form-container">
    <!-- 返回 -->
    <div class="callBack fontRegular" @click="goBack">
      <el-icon><ArrowLeftBold /></el-icon>
      &nbsp;返回
    </div>
    <div class="wrap-title">
      <div class="sub-title fontMedium">免费获取完整项目demo</div>
    </div>
    <div class="form">
      <el-form ref="formRef" :rules="clientFormRules" :model="clientForm">
        <div class="input-top">
          <el-form-item>
            <ml-input
              placeholder="联系电话"
              v-model="clientForm.contactWay"
            ></ml-input>
          </el-form-item>
          <div></div>
          <el-form-item>
            <ml-input
              placeholder="称呼姓名"
              v-model="clientForm.named"
            ></ml-input>
          </el-form-item>
        </div>
      </el-form>

      <div class="phone-box">
        <div class="phone">
          <div class="image">
            <img :src="phoneImage" alt="" />
          </div>
          <div class="fontRegular">177-2247-6866</div>
        </div>
      </div>
      <div class="btn">
        <ml-button id="form-submit" useLoading @submit="formSubmit">
          <span class="fontRegular">点击提交</span>
        </ml-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, ref, getCurrentInstance } from "vue";
import datajs from "./data.js";

const { proxy } = getCurrentInstance();

const { formRef, clientForm, clientFormRules, formSubmit, goBack } =
  datajs.apply(proxy);

// 电话
const phoneImage = require("@/assets/images/home/phone-icon.png");
</script>

<style lang="scss" scoped>
.demo-form-container {
  height: 100%;
  box-sizing: border-box;

  // 返回
  .callBack {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: white;
    margin-top: 44px;
    padding-left: 8px;
    cursor: pointer;

    .el-icon {
      margin-top: 2px;
    }
  }
  .wrap-title {
    margin-top: 100px;
    .sub-title {
      color: white;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  .form {
    width: 280px;
    margin: auto;
    .input-top {
      width: 100%;
      .el-form-item {
        width: 100% !important;
        .el-form-item__content {
          width: 100% !important;
        }
        .ml-input {
          width: 100% !important;
        }

        .el-input {
          .el-input__wrapper {
            font-size: 13px;
            width: 100% !important;
            height: 48px !important;
            border: none;
            border-radius: 5px;
            padding: 0 15px;
            box-sizing: border-box;
            outline: none;
          }
        }
      }
    }

    .phone-box {
      display: flex;
      color: white;
      margin-top: 10px;
      flex-direction: column;
      .phone {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .image {
          width: 13px;
          height: 13px;
          display: flex;
          margin-right: 6px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        div {
          font-size: 13px;
        }
      }

      .notes {
        font-size: 12px;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      button {
        width: 160px;
        height: 48px;
        background: #ffffff;
        border: none;
        color: #0a41ff;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        transition: background 300ms;
        &:active {
          background-color: #ffffffe6;
        }
      }
    }

    input::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #9b9eb3;
      font-size: 13px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #9b9eb3;
      font-size: 13px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #9b9eb3;
      font-size: 13px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #9b9eb3;
      font-size: 13px;
    }

    textarea::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #9b9eb3;
      font-size: 13px;
    }
    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #9b9eb3;
      font-size: 13px;
    }
    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #9b9eb3;
      font-size: 13px;
    }
    textarea:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #9b9eb3;
      font-size: 13px;
    }
  }
}

.mobile-wrap {
  .demo-form-container {
    .callBack {
      margin-top: 10px !important;
    }
  }
}
</style>

<style lang="scss">
.input-top {
  .el-input {
    .el-input__wrapper {
      font-size: 13px;
      width: 100% !important;
      height: 48px !important;
      border: none;
      border-radius: 5px;
      padding: 0 15px;
      box-sizing: border-box;
      outline: none;
    }
  }
}
</style>
